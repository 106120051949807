//popup evadi manutenzione
import { useState } from "react";
import "./Popup.css";
import firebase from "firebase/app";

import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@mui/material/Button';
import { IconButton } from "@material-ui/core";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { formatData } from "../EngineClienti/engineClienti";
import SecurityIcon from '@mui/icons-material/Security';
const EvadiManutenizone = (props) =>{

    const auth = firebase.auth();
    const db = firebase.firestore();
    const [selectedOptionEsito, setOptionEsito] = useState(""); //esito
    const [reportz, setReport] = useState(); //report
    const [data,setData] = useState(""); //data 
    const [titolo,setTitolo] = useState(""); //titolo
    const [descrizione,setDesc] = useState(""); //descrizione

    const [showSelezionaElementi,setShowSelezionaElementi] = useState(false); //trigger seleziona elementi
    const [elementiSelezionati,setElementiSelezionati] = useState([]) //elementi selezionati

    {console.log(props.data)}

    const SelezionaElementi =()=>{

      function handleClickElemento(event){
        var checked = event.target.checked
        console.log(event.target)
        if(checked){
        setElementiSelezionati((prev)=>[...prev,event.target.value])
        }else{
          var index = props.data.Elementi.indexOf(event.target.value)
          setElementiSelezionati(elementiSelezionati.filter((i,itemIndex)=>index!==itemIndex))
        }
      }

    const GetEle = (props) =>{
      const [name,setName] = useState(null)
      const [name2,setName2] = useState(null)
  
        var elementoString = props.elemento.split('/')
        db.collection('Elementi').doc(elementoString[elementoString.length-1]).get().then((el)=>{
          setName(el.data().Nome)
          setName2('('+el.data().Categoria+' - '+el.data().Macrocategoria+') ')
        })
   
  
  
        
        return (<div style={{display:'flex',alignItems:'center',marginTop:'0.5vh'}}>
          <input checked={elementiSelezionati.indexOf(props.elemento)!==-1}  value={props.elemento} onClick={(e)=>{handleClickElemento(e)}} type="checkbox" style={{verticalAlign:"middle"}}/>
          {name!==null&&name2!==null?
          <h4 style={{verticalAlign:"middle",fontSize:15,color:'black',margin:0,fontWeight:'normal'}}><b>{name}</b> {name2}</h4>:null}
          </div>)
      }


      return (
        <div className="pop-opac">
      <div style={{borderRadius:10,display:"inline-flex",flexDirection:"column",position:"fixed",marginLeft:"auto",marginRight:"auto",left:'10vw',right:0,top:0,bottom:"20vh",marginTop:"auto",marginBotton:"auto",zIndex:10,width:"60vw",height:"70vh",backgroundColor:"white"}}>
                  <div style={{}}>
                  <button
                className="cancelIconButton"
                style={{marginLeft:"0.5vw",marginRight:"auto",padding:0,marginTop:'1vh',marginBottom:0}}
                onClick={() => setShowSelezionaElementi(false)}
              >
                <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
              </button>
              <h4 className="title-section" style={{marginLeft:'1.5vw'}}>Seleziona gli elementi che NON hanno superato la manutenzione</h4>
        </div>
        <div style={{overflowY:"auto",disply:"inline-flex",flexDirection:"column",width:"50vw",maxHeight:"50vh",marginLeft:"1.5vw",marginRight:"auto"}}>
          {props.data.Elementi.map((elemento,index)=>(
           <GetEle elemento={elemento} key={index} index={index}/>
          ))}
        </div>
        <div style={{height:"10%",alignItems:"center",marginTop:'2vh',marginLeft:'1.5vw'}}>
      <button className="main-button" onClick={()=>setShowSelezionaElementi(false)} variant="contained">Conferma</button>
      </div>
        </div></div>)
    } //componente per selezionare gli elementi
  
{console.log('ciao'+props.main.Id,props.data.Elementi)}
     //evade sottomanutenzione
    const handleSubmitS=(event)=>{
      event.preventDefault();
      console.log(props.data)
      const titoloRe = titolo.toString();
      const descrizioneRe = descrizione.toString();
      const dateSplitted = data.split("-");
      const year = dateSplitted[0];
      const month = dateSplitted[1];
      const day= dateSplitted[2];
      if(selectedOptionEsito==="Positivo"){
        
       db.collection("Manutenzioni").doc(props.data.key).update({
              Stato: "Conclusa",
              Esito: "Positivo",
              Report:reportz,
          }).then(()=>{
            db.collection('Manutenzioni').doc(props.main.Id).update({
              Esito: 'Positivo',
              Sott_Attivi: false,
            })
            if(props.main.Type==='doc'){
              db.collection('Documenti').doc(props.data.DocId).update({
                Verified: true
              })
            }
            const listaS = JSON.parse(sessionStorage.getItem('scadenze'));
            var arr = listaS
            var index = arr.findIndex((e)=>e.Id===props.main.Id)
            console.log(index,props.main)
            arr[index].Esito= 'Positivo'
            arr[index].Sott_Attivi = false
            sessionStorage.setItem('scadenze', JSON.stringify(arr));
            props.setScad(arr)


            props.getAtt(props.email)

              props.setTrigger(false);

              alert("Azione correttiva evasa positivamente.")
            })
            
      }else if(selectedOptionEsito==="Negativo"){

        console.log(props.data)
        var date2 = new Date();
        var days2 = date2.getDate();
        if(days2<10){
          days2 = '0' + days2;
        }
            var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
            var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
              var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
          db.collection('Users').doc(props.email).get().then((me)=>{
            db.collection("Users").doc(props.cliente).collection("Notifiche").doc().set({
              Nome_Azienda:me.data().Nome_Azienda,
              Nome_Cognome:me.data().Nome+" "+me.data().Cognome,
              Email:me.data().Email,
              Messaggio:"ha aggiunto un'azione correttiva",
              Data:dataFormattata,
              Oggetto:props.data.Titolo
            })
          })

          if(props.data.Type==='doc'){

            var batch = db.batch();
  
                        // Aggiorno la manutenzione e la chiudo
                        batch.update(db.collection("Manutenzioni").doc(props.data.key),{
                            Stato: "Conclusa",
                            Esito: "Negativo",
                            Report:reportz, 
  
                        })
                        var newManutenzioneDoc = db.collection("Manutenzioni").doc();
                 
                        // Creo la nuova manutenzione
                        batch.set(newManutenzioneDoc,{
                          Nome_AziendaOff: props.data.Nome_AziendaOff,
                          Nome_AziendaCli: props.data.Nome_AziendaCli,                  
                            Titolo: titoloRe,
                            Descrizione: descrizioneRe,
                            Stato: "Pianificata",
                            Nome: props.data.Nome,
                            Cognome: props.data.Cognome,
                            Oggetto: props.data.Oggetto,
                            Cliente: props.data.Cliente,
                            //Cliente: auth.currentUser.email,
                            ID: props.data.key,
                            Sede: props.data.Sede_cli,
                            Indirizzo: props.data.Indirizzo,
                            Operatore: props.data.Operatore,
                            DocId: props.data.DocId
                        })

                         // Aggiungo la nuova manutenzione all'offritore e al cliente
                         batch.set(db.collection("Users").doc(props.data.Cliente).collection("Sedi").doc(props.sede_cli).collection("Manutenzioni").doc(props.sottoRef).collection("Manutenzioni").doc(newManutenzioneDoc.id),{
                          ref:newManutenzioneDoc.id
                      })
          
                      batch.set(db.collection("Users").doc(props.email).collection("Clienti").doc(props.data.Cliente).collection("Manutenzioni").doc(props.sottoRef).collection("Manutenzioni").doc(newManutenzioneDoc.id),{
                          ref:newManutenzioneDoc.id
                      })

                      batch.commit().then(()=>{
                        var date2 = new Date();
                        var days2 = date2.getDate();
                        if(days2<10){
                          days2 = '0' + days2;
                        }
                            var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
                            var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
                              var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
                          db.collection('Users').doc(props.email).get().then((me)=>{
                            db.collection("Users").doc(props.cliente).collection("Notifiche").doc().set({
                              Nome_Azienda:me.data().Nome_Azienda,
                              Nome_Cognome:me.data().Nome+" "+me.data().Cognome,
                              Email:me.data().Email,
                              Messaggio:"ha evaso un'azione correttiva negativamente.",
                              Data:dataFormattata,
                              Oggetto:props.data.Titolo
                            }).then(() => {
                              props.getAtt(props.email)
                            alert("Azione correttiva evasa correttamente, è stata creata un'ulteriore azione correttiva, aggiorna la pagina per visualizzarla.")
            
                            props.setTrigger(false)
            
                            });
                          })
                         
                        })
          }else{

            var elementiInfo = [];


            var elementi = elementiSelezionati.length>0 ? elementiSelezionati : props.data.Elementi;      

          var elementiUpdated = [];
            

          props.data.Elementi.forEach((sing)=>{
            elementiUpdated.push({
              Elemento:sing,
              Esito:elementiSelezionati.indexOf(sing)>=0 ? "Negativo":"Positivo" // se è presente l'elemento vuol dire che NON ha passato la manutenzione
            })
            
          })

          

          props.data.Elementi.forEach((elemento)=>{
              db.doc(elemento).get().then((docz)=>{
                db.collection('Elementi').doc(docz.data().ref).get().then((doc)=>{
                  var dati = doc.data();
                  dati.path = elemento
                  elementiInfo.push(dati)

                  if(elementiInfo.length===props.data.Elementi.length){
                      // Eseguo scrittura
                      var batch = db.batch();

                      // Aggiorno la manutenzione e la chiudo
                      batch.update(db.collection("Manutenzioni").doc(props.data.key),{
                          Stato: "Conclusa",
                          Esito: "Negativo",
                          Report:reportz, 
                          Elementi:elementiUpdated

                      })
                      var newManutenzioneDoc = db.collection("Manutenzioni").doc();
               
                      // Creo la nuova manutenzione
                      batch.set(newManutenzioneDoc,{
                        Nome_AziendaOff: props.data.Nome_AziendaOff,
                        Nome_AziendaCli: props.data.Nome_AziendaCli,                  
                          Titolo: titoloRe,
                          Descrizione: descrizioneRe,
                          Stato: "Pianificata",
                          Nome: props.data.Nome,
                          Cognome: props.data.Cognome,
                          Oggetto: props.data.Oggetto,
                          Cliente: props.data.Cliente,
                          //Cliente: auth.currentUser.email,
                          ID: props.data.key,
                          Sede: props.data.Sede_cli,
                          Indirizzo: props.data.Indirizzo,
                          Elementi:elementi,
                          Operatore: props.data.Operatore,
                      })
          
                      // Per ognuno degli elementi aggiungo il ref della nuova manutenzione
                      elementiInfo.forEach((item)=>{
                          var numeroManutenzioni = 0;
                          if(item.NumeroManutenzioni!==undefined){numeroManutenzioni = item.NumeroManutenzioni}
                          numeroManutenzioni++;
                          batch.update(db.doc(item.path),{NumeroManutenzioni:numeroManutenzioni})
                  
                          batch.set(db.doc(item.path).collection("Interventi").doc(newManutenzioneDoc.id),{
                            ref: newManutenzioneDoc.id,
                            name:item.Nome,
                            SottoAttività: true, 

                          })
                        })
          
                     
                      // Aggiungo la nuova manutenzione all'offritore e al cliente
                      batch.set(db.collection("Users").doc(props.data.Cliente).collection("Sedi").doc(props.data.Sede).collection("Manutenzioni").doc(props.main.Id).collection("Manutenzioni").doc(newManutenzioneDoc.id),{
                          ref:newManutenzioneDoc.id
                      })
          
                      batch.set(db.collection("Users").doc(props.email).collection("Clienti").doc(props.data.Cliente).collection("Manutenzioni").doc(props.main.Id).collection("Manutenzioni").doc(newManutenzioneDoc.id),{
                          ref:newManutenzioneDoc.id
                      })
          
                      // Aggiungo le annotazioni del calendario ad entrambi
                      var annotazioneCalendario = new Object();
                     
          
            
                    batch.commit().then(()=>{
                      var date2 = new Date();
                      var days2 = date2.getDate();
                      if(days2<10){
                        days2 = '0' + days2;
                      }
                          var month2 = ('0'+(date2.getMonth()+1)).slice(-2);
                          var minutes2 =  ('0'+(date2.getMinutes())).slice(-2);
                            var dataFormattata = days2+"-"+month2+"-"+date2.getFullYear()+" "+(date2.getHours())+":"+minutes2;
                        db.collection('Users').doc(props.email).get().then((me)=>{
                          db.collection("Users").doc(props.cliente).collection("Notifiche").doc().set({
                            Nome_Azienda:me.data().Nome_Azienda,
                            Nome_Cognome:me.data().Nome+" "+me.data().Cognome,
                            Email:me.data().Email,
                            Messaggio:"ha evaso un'azione correttiva",
                            Data:dataFormattata,
                            Oggetto:props.data.Titolo
                          }).then(() => {
                            props.getAtt(props.email)
                          alert("Azione correttiva evasa correttamente, è stata creata un'ulteriore azione correttiva, aggiorna la pagina per visualizzarla.")
          
                          props.setTrigger(false)
          
                          });
                        })
                       
                      })
                  }
              })
            })
            })
          }

         
      }
  }
    return props.trigger ? (
        <div className="pop-opac" >
      <div className="popup" style={{backgroundColor:"white", width:'72vw', left:'18vw',height:'80vh'}}>
              <div style={{textAlign:'left', width: 'fit-content'}}>
              <button
                className="cancelIconButton"
                
                onClick={() => props.setTrigger(false)}
              >
                <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
              </button>
              </div>
              <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
  
              <label  className="page-title">EVADI AZIONE CORRETTIVA</label></div>
              <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>
          <label  style={{ fontSize:'1vw',color:'#5C6672', fontWeight:'normal',fontFamily:'Montserrat'}}>Compila il riepilogo dell'azione correttiva per concluderla.</label>
          </div> <br/>
          <div style={{display:'flex'}}>
          <div style={{padding:"10px",backgroundColor:"white",border: '1px solid lightgrey', width:'25vw', borderRadius:'5px', marginLeft:'auto', marginRight:'5vw',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height:'58vh',overflowY:'auto', overflowX:'hidden'}}>
            <div style={{borderBottom: '1px solid lightgrey',}}>
            <div style={{width:'fit-content',marginBottom:'5px'}}>
          <h4  className="header-normal" style={{fontSize:'1.2rem'}}>INFO AZIONE CORRETTIVA</h4></div>
          </div>
          <div style={{marginTop:'0.5rem'}}>
          
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'1vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">TITOLO</label></div>
          <h4 className="normal-text">{props.data.Titolo}</h4> 
          </div> 
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">CLIENTE</label></div>
          <h4 className="normal-text">{props.data.Nome_AziendaCli} ({props.data.Cliente})</h4> 
          </div> 
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">SEDE D'INTERVENTO</label></div>
          <h4 className="normal-text">{props.data.Sede_cli}</h4> 
          </div> 
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">OGGETTO</label></div>
          <h4 className="normal-text">{props.data.Oggetto}</h4> 
          </div> 
          <div style={{width:'100%',borderBottom:'1px solid lightgrey',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">DESCRIZIONE</label></div>
          <h4 className="normal-text">{props.data.Descrizione}</h4> 
          </div> 
          <div style={{width:'100%',marginBottom:'0.5vh',paddingBottom:'0.5vh'}}>
          <div style={{width:'fit-content'}}>
          <label  className="sub-text-b">DATA D'INTERVENTO</label></div>
          <h4 className="normal-text">{props.data.Data?formatData(props.data.Data):'Non inserita'}</h4> 
          </div> 
          </div>
          </div>
  
          <div style={{padding:"10px",backgroundColor:"white", border: '1px solid lightgrey', width:'25vw', borderRadius:'5px',  marginRight:'auto',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height:'58vh',overflowY:'auto', overflowX:'hidden'}}> 
          <form>
          <div style={{borderBottom: '1px solid lightgrey',}}>
          <div style={{width:'fit-content',marginBottom:'5px'}}>
          <h4  className="header-normal" style={{fontSize:'1.2rem'}}>RIEPILOGO AZIONE CORRETTIVA</h4></div>
          </div>
          <div style={{width:'fit-content',marginTop:'0.5rem'}}>
          <label   className="sub-text-b">ESITO COMPLESSIVO*</label></div>
            <div style={{display:'flex'}}>
            <label className="normal-text">
            <input
              value="Positivo"
              
              type="radio"
              onChange={() => setOptionEsito("Positivo")}
              checked={selectedOptionEsito === "Positivo"}
            ></input>
            Positivo</label>
            <label className="normal-text" style={{marginLeft:'5px'}}>
            <input
              type="radio"
              value="Negativo"
              onChange={() => setOptionEsito("Negativo")}
              checked={selectedOptionEsito === "Negativo"}
            ></input>
            Negativo</label>
            </div>
            {selectedOptionEsito!==""?<div>        <div style={{width:'fit-content'}}>
              <br/>
  <label  className="sub-text-b">OSSERVAZIONI*</label></div>
  <div style={{width:'fit-content',marginTop:'0.5rem'}}>
                <input
                  type="text"
                  name="reportP"
                 className="input-style"
                  placeholder="Inserisci..."
                  onChange={(e)=>setReport(e.target.value)}
                ></input></div></div>:null}
                <br/>
            {selectedOptionEsito === "Negativo" ? (
              <div>
                        <div style={{width:'fit-content'}}>
                        <label  className="title-section">AGGIUNTA AZIONE CORRETTIVA</label>
            {props.data.Type!=='doc'?<div style={{display:'flex',width:"fit-content",alignItems:'center',justifyContent:'center'}} >
              
                  <h4 className="text-buttons" onClick={()=>setShowSelezionaElementi(true)}>Seleziona gli elementi con esito negativo*</h4>
                  <IconButton onClick={()=>setShowSelezionaElementi(true)} style={{height:'fit-content',width:'fit-content',backgroundColor:'#192bc2',marginLeft:'1vw'}} aria-label="Osserva elementi">
                    <MenuOpenIcon style={{color:"white",width:'1vw',height:'1vw'}}/></IconButton>
                  <SecurityIcon style={{color:'#5C6672',marginLeft:'0.5vw',width:'1vw'}}></SecurityIcon>
                  <h4 style={{fontSize:'1vw',color:'#5C6672', fontWeight:'bold', fontFamily:'Montserrat',marginTop:'auto',marginBottom:'auto'}}>{elementiSelezionati.length}</h4>
                  </div>:null}
            {showSelezionaElementi===true&&<SelezionaElementi/>}   
            </div>
            <div style={{width:'fit-content'}}>
            <label className="normal-text">AZIONE CORRETTIVA*</label></div>
            <div style={{width:'fit-content'}}>
            <input
                  type="text"
                  name="titolo"
                  onChange={(e)=>setTitolo(e.target.value)}
                  className="input-style"
                  placeholder="Inserisci..."
                ></input>
                </div>
                             <div style={{width:'fit-content',marginTop:'0.5rem'}}>
                             <label  className="normal-text">DESCRIZIONE AZIONE CORRETTIVA*</label>
                             </div>
                             <div style={{width:'fit-content'}}>
  
                             <input
                  type="text"
                  name="descrizione"
                  onChange={(e)=>setDesc(e.target.value)}
                  className="input-style"
                  placeholder="Inserisci..."
                ></input></div>

                <br/>
                <div style={{width:'fit-content'}}>
                <button onClick={handleSubmitS} className='main-button' value="Evadi intervento">EVADI</button>
                </div>
                <br />
              <br/>
                
              </div>
            ) : selectedOptionEsito==='Positivo'?(
              <div style={{width:'fit-content'}}>
              <button onClick={handleSubmitS} className='main-button' value="Evadi intervento">EVADI </button>
              </div>
            ):null}
          </form>
        </div>
        </div>
      </div>
      </div>
    ) : (
      ""
    );
  };

export default EvadiManutenizone