//pagina di accesso
import "./Style/login.css";
import React from "react";
import { withRouter } from "react-router";
import { LeftScreen } from "./Components/LeftScreen"; //parte sinistra schermo
import { RightScreen } from "./Components/RightScreen"; //parte destra schermo 
import logo from '.././images/mysic-lungo2.png'
import { useState } from "react";
import { FormLogin } from "./Components/form";
import { RegisterLink } from "./Components/registerLink";
const LoginPage = ({ history }) => {


  return (
    <div
  
    style={{
      backgroundColor: 'white',
      backgroundImage: `
        radial-gradient(circle at 20% 20%, rgba(255, 99, 71, 0.2) 10%, transparent 30%),
        radial-gradient(circle at 80% 40%, rgba(30, 144, 255, 0.2) 10%, transparent 30%),
        radial-gradient(circle at 60% 80%, rgba(128, 128, 128, 0.2) 10%, transparent 30%),
        radial-gradient(circle at 40% 70%, rgba(100, 149, 237, 0.2) 10%, transparent 30%)
      `,
      backgroundSize: 'cover',
      width: '100vw',
      height: '100vh',
      position: 'fixed',
    }}    >
      <div style={{height:'100vh', width:'100vw',display:'flex',justifyContent: 'center'}}>

      <div style={{marginTop:'10vh',backgroundColor:'white',width:'15vw',paddingLeft:'1vw',borderRadius:'20px',padding:'50px',boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 1.6px 2px",height:'55vh',paddingTop:'20px'
}} className="pre-content">
  <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',}}>
  <img alt='logo' src={logo} style={{width:'8vw',}}></img>
  </div>
  <h4 className="pre-text-big" style={{fontFamily:'Poppins',color:'black',fontWeight:'500',marginBottom:0,marginTop:0,fontSize:'1.7vw',marginLeft:'auto',marginRight:'auto',width:'fit-content'}}>Bentornato</h4>

  <p style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',fontFamily:'Montserrat',color:'#14213d',marginTop:'0px',fontSize:'13px',fontWeight:'600'}}>Accedi per continuare</p>
  <br/>
  <FormLogin history={history}/>
  <RegisterLink/>
 </div>
      {/*<LeftScreen/>*/}
      {/*<RightScreen history={history}/>*/}
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
