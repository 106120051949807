//pagina impostazioni
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import NavBar from "../NavBar/NavBar";
import AccountS from "./AccountSection/AccountS"; //componente account
import AltroS from "./AltroSection/AltroS"; //componente altro
import SottoaccountS from "./SottoaccountSection/SottoaccountS"; //compomenente sottoaccount
import Esportazioni from "./Esportazioni";
import NavBar2 from "../NavBar/NavBar2";
import firebase from "firebase";
import { getUtente,checkDeleteS,checkWriteS } from "../EngineClienti/engineClienti";
import Notifiche from "../Notifiche/Notifiche";
import '../general.css'
const Impostazioni = ({history}) => {

    const db = firebase.firestore(); //database firebase
    const auth = firebase.auth(); //autentication firebase
    

    const [section,setSection] = useState(0) //sezione
    const [user,setUser] = useState(null)

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
      if (user) {
        try {
          getUtente(db,auth,setUser)
        } catch (e) {}
      }
    });
    },[auth])


    return <div style={{overflow:'hidden',backgroundColor:'#f6f6f9'}}>
           {user!==null&&user!==null?<Notifiche user={user}/>:null}
           {user!==null&&user!==undefined?user.Tipo==='Lavoratore'?<NavBar2/>:<NavBar/>:null}
        <div style={{position:'absolute',width:'60vw',left:'15vw',height:'70vh',top:'1rem'}}>
        <h4 className="page-title" style={{marginTop:0}}>IMPOSTAZIONI</h4>
        <div className="box-shadow" style={{display:'flex',width:'80vw',backgroundColor:'white',marginTop:'1.7rem',borderRadius:'15px'}}>
        <div style={{alignItems:'center',height:'88vh',backgroundColor:'white',borderBottomLeftRadius:'15px',borderTopLeftRadius:'15px',width:'10rem',border:'1px solid #7d8da1'}}>
        <button onClick={()=>setSection(0)} style={{borderTopLeftRadius:'15px'}} className={section===0?"section-style-on":'section-style-off'}>Account</button><br/>
        <button onClick={()=>setSection(1)} className={section===1?"section-style-on":'section-style-off'}>Esportazioni</button><br/>
       {user!==null?user.TipoS===null? <button onClick={()=>setSection(3)} className={section===3?"section-style-on":'section-style-off'}>Sottoaccount</button>:null:null}
       <button onClick={()=>setSection(2)} className={section===2?"section-style-on":'section-style-off'}>Altro</button>
        </div>
        {section===0?<AccountS/>:null}
        {section===1?<Esportazioni/>:null}
        {section===2?<AltroS/>:null}
        {section===3?<SottoaccountS user={user} db={db}/>:null}

        </div>
        
        </div>
    </div>
}

export default withRouter(Impostazioni);