//componente con descrizione di ciò che mysic offre
import '../Style/prelogin.css'
import doc from '../../images/documenti.jpg'
import int from '../../images/interventi.JPG'
import man from '../../images/scadenze.JPG'
import map from '../../images/mappe.JPG'
import { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import dash from '../../images/dash.JPG'
import meet from '../../images/meeting.png'
import ele from '../../images/ele.JPG'
export default function GridLayout() {

    const [section,setSection]= useState(0)

    return (
        <div style={{width:'100vw',overflowX:'hidden',paddingTop:10,backgroundColor:'#f6f6f9',position:'absolute',height:'100%'}}>

            <div style={{width:'100%',alignItems:'center',flexDirection:'row',justifyContent:'center',display:'flex',marginTop:'8vh'}}>
            <h4 style={{fontFamily:'Poppins',color:'#14213d',fontSize:'35px',width:'60%',textAlign:'center'}}>Mysic è la soluzione definitiva per gestire la sicurezza aziendale in modo semplice e veloce.</h4>
            </div>

            <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'fit-content',marginLeft:'auto',marginRight:'auto'}}>
            <button onClick={()=>setSection(0)} style={{backgroundColor:section===0?'#192bc2':'#14213d',color:'white',width:'200px',fontFamily:'Poppins',fontWeight:section===0?'bold':'normal',
                borderRadius:50,border:'none',padding:'10px',height:'50px',fontSize:'15px',marginRight:'15px',cursor:'pointer'}}>OTTIMIZZATO</button>
                <button onClick={()=>setSection(1)} style={{backgroundColor:section===1?'#192bc2':'#14213d',color:'white',width:'200px',fontFamily:'Poppins',fontWeight:section===1?'bold':'normal',
                borderRadius:50,border:'none',padding:'10px',height:'50px',fontSize:'15px',marginRight:'15px',cursor:'pointer'}}>UNO SGUARDO</button>
                <button onClick={()=>setSection(2)} style={{backgroundColor:section===2?'#192bc2':'#14213d',color:'white',width:'200px',fontFamily:'Poppins',fontWeight:section===2?'bold':'normal',
                borderRadius:50,border:'none',padding:'10px',height:'50px',fontSize:'15px',marginRight:'15px',cursor:'pointer'}}>GESTIONALE</button>
                <button onClick={()=>setSection(3)} style={{backgroundColor:section===3?'#192bc2':'#14213d',color:'white',width:'200px',fontFamily:'Poppins',fontWeight:section===3?'bold':'normal',
                borderRadius:50,border:'none',padding:'10px',height:'50px',fontSize:'15px',marginRight:'15px',cursor:'pointer'}}>AVANZAMENTO</button>
            </div>

            <div style={{display:'flex',alignItems:'center',width:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'5vh'}}>
             <div style={{height:'55vh',width:'23vw',}}>
               {section===0?<div>
                <h4 style={{fontFamily:'Poppins',fontWeight:'bold',fontSize:'20px',margin:0,maxWidth:'20vw'}}>Account ottimizzati per manutentori e clienti</h4>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Mysic offre piattaforme distinte in base alla tue necessità di ricevere o fornire servizi.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Ogni piattaforma possiede funzionalità uniche e specifiche per adattarsi a ciò di cui hai bisogno.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Le piattaforme sono progettate per rendendere l'interazione tra manutentore e cliente il più semplice, comodo e veloce possibile.</h4>
                </div>
                </div>:null} 

                {section===1?<div>
                <h4 style={{fontFamily:'Poppins',fontWeight:'bold',fontSize:'20px',margin:0,maxWidth:'20vw'}}>Ti basta uno sguardo per essere al sicuro</h4>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Le nostre pagine sono progettate per mettere in evidenza i dati importanti guidando il tuo occhio verso ciò che cerchi.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Grazie alla dashboard riepilogativa, puoi trovare subito le informazioni che contano.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Filtri rapidi e layout ottimizzati rendono ogni dato accessibile in pochi istanti.</h4>
                </div>
                </div>:null} 
                {section===2?<div>
                <h4 style={{fontFamily:'Poppins',fontWeight:'bold',fontSize:'20px',margin:0,maxWidth:'20vw'}}>Gestisci ogni aspetto della tua sicurezza</h4>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Pagine dedicate ti permettono di organizzare elementi, documenti, interventi, scadenze e mappe delle tue sedi per una gestione completa e intuitiva.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Modifica e personalizza ogni parametro dei tuoi dati, adattando il gestionale alle tue esigenze specifiche per avere il controllo totale.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Con Mysic, non solo organizzi tutto in piattaforma, ma hai la possibilità di scaricare backup sempre aggiornati in formato Excel, garantendo sicurezza e accessibilità ovunque.</h4>
                </div>
                </div>:null} 
                {section===3?<div>
                <h4 style={{fontFamily:'Poppins',fontWeight:'bold',fontSize:'20px',margin:0,maxWidth:'20vw'}}>Non lasciare nulla in sospeso</h4>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Grazie a uno scadenziario intuitivo, puoi avere sempre sotto controllo prossime attività e ritardi, con una visione chiara e immediata di ciò che è più urgente.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Mysic ti tiene sempre aggiornato, collegando scadenze e interventi ai dettagli di elementi e documenti, per un flusso di lavoro completamente integrato.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'20vw',marginTop:'20px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Con Mysic resti sempre aggiornato grazie a notifiche in tempo reale ed email automatizzate.</h4>
                </div>
                </div>:null} 
            </div>   
            <div style={{height:'55vh',width:'35vw'}}>
            {section===0?<img src={meet} alt='meet' style={{width:'100%'}}></img>:null}

                {section===1?<img src={dash} alt='dash' style={{width:'100%'}}></img>:null}
                {section===2?<img src={ele} alt='ele' style={{width:'100%'}}></img>:null}
                {section===3?<img src={man} alt='man' style={{width:'100%'}}></img>:null}

                </div>       
            </div>
         
        </div>
       
    )

}