//form di login
import React, { useCallback, useState } from "react";
import '../Style/login.css'
import app from "../../base";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
export function FormLogin(props) {
  const [passwordShown, setPasswordShown] = useState(false); //variabile per visualizzare la pw o no

  const handleLogin = useCallback(
    //funzione per gestire il login
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value)
          .then(() => {
            props.history.push("/home"); //se loggato manda alla homepage
          }); //funzione di login firebase
      } catch (error) {
        alert("Credenziali errate!");
        console.log(error);
      }
    },
    [props.history]
  ); //funzione di login

  return (
    <form onSubmit={handleLogin}>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
        }}
      >
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',border:'1px solid grey',borderRadius:'5px',paddingLeft:'2px',}}>
          <EmailIcon style={{color:'#14213d'}}/>
          <input
        style={{width:'100%',backgroundColor:'white',fontFamily:'Poppins',marginLeft:'5px',border:'none',outline:'none'}}
          type="email"
          name="email"
          placeholder="Inserisci il tuo indirizzo e-mail"
        />
        </div>
        
      </div>
      <br />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
        }}
      >
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',border:'1px solid grey',borderRadius:'5px',paddingLeft:'2px'}}>
          <LockIcon style={{color:'#14213d'}}/>
          <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            position:'relative',
            display:'flex',
            width:'100%'
          }}
        >
          <input
            name="password"
            style={{width:'100%',backgroundColor:'white',fontFamily:'Poppins',marginLeft:'5px',border:'none',outline:'none'}}
                type={passwordShown===true ? "text" : "password"}
          />
 <button type="button" onClick={()=>setPasswordShown(!passwordShown)} style={{width:'fit-content',backgroundColor:'transparent',border:'none',height:'100%'}}>
          {passwordShown===true?<VisibilityOffIcon style={{color:'black',marginTop:5,marginBottom:'auto'}}/>:<VisibilityIcon style={{color:'black',marginTop:5,marginBottom:'auto'}}/>}
        </button>
        </div>
        </div>

     
        {/* icona per mostrare o no la pw*/}
       
      </div>
      <div style={{ height: "15px" }}></div>

      <div
        style={{
          marginTop: "3vh",
         
          width: "100%",
        }}
      >
        <button
          type="submit"
          style={{
            backgroundColor: "#192bc2",
            color: "white",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: "0.7vw",
            border: "2px solid #192bc2",
            borderRadius: 5,
            width: "100%",
            cursor: "pointer",
            height: "4vh",
            display: "flex",
            marginTop:"3vh",
            
          }}
        >
     <h4 style={{marginTop:'auto',marginBottom:'auto',fontSize:'0.8vw',fontFamily:'Poppins',fontWeight:'500',marginLeft:'auto',marginRight:'auto'}}>Accedi</h4>
     </button>
      </div>
    </form>
  );
}
