//sezione azioni correttive
import React, { useState, useEffect } from "react";
import WarningIcon from '@mui/icons-material/Warning';
import firebase from "firebase/app";
import EvadiManutenzione from "../../Popups/EvadiManutenzione";
import { checkWriteS } from "../../EngineClienti/engineClienti";
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';
import { IconButton, Button, Tooltip } from "@mui/material";
import { formatData } from "../../EngineClienti/engineClienti";
import DeleteIcon from '@mui/icons-material/Delete';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AddIcon from '@mui/icons-material/Add';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';
import EditIcon from '@mui/icons-material/Edit';

const Recupero = (props) => {

    const [activity,setActivity] = useState([]) //lista sottointerventi
    const auth = firebase.auth();
    const db = firebase.firestore();
    
    useState(()=>{
            getActsOff(props.scadenza.Operatore)

    },[])

    function getActsOff(email){
        setActivity([])
        db.collection("Users") // Prendo le sedi del master account
        .doc(email)
        .collection("Clienti")
        .doc(props.scadenza.Cliente)
        .collection("Manutenzioni")
        .doc(props.scadenza.Id)
        .collection('Manutenzioni')
        .get().then((querySnapshot)=>{
          querySnapshot.docChanges().forEach((actz)=>{
              db.collection('Manutenzioni').doc(actz.doc.data().ref).get().then((act)=>{
                setActivity((prev)=>{ return [...prev,{
                  key: act.id,
                  Titolo: act.data().Titolo,
                    Sede: act.data().Sede,
                    Descrizione: act.data().Descrizione,
                    Nome: act.data().Nome,
                    Cognome: act.data().Cognome,
                    Priorità: act.data().Priorità,
                    Oggetto: act.data().Oggetto,
                    Stato: act.data().Stato,
                    Cliente: act.data().Cliente,
                    Sede_cli: act.data().Sede,
                    Elementi: act.data().Elementi,
                    Report: act.data().Report,
                    Esito: act.data().Esito,
                    SottoAttività: act.data().SottoAttività?act.data().SottoAttività:false,
                    Indirizzo: act.data().Indirizzo,
                    Nome_AziendaCli: act.data().Nome_AziendaCli,
                    Nome_AziendaOff: act.data().Nome_AziendaOff,
                    InterventiPath: act.data().InterventiPath,
                    Categoria: act.data().Categoria,
                    Macrocategoria: act.data().Macrocategoria,
                    Note: act.data().Note?act.data().Note:null,
                    Data: act.data().Data?act.data().Data:null,
                    Operatore: props.user.Tipo==='Lavoratore'||props.user.TipoSott==='Lavoratore'?( act.data().Operatore ? act.data().Operatore:props.user.Tipo==='SottoAccount'?props.user.MasterAccount:auth.currentUser.email):( act.data().Operatore ? act.data().Operatore: props.user.Fornitore),
                    Archiviato: act.data().Archiviato ? act.data().Archiviato : false,
                    Type:act.data().Type?act.data().Type:null,
                    DocId: act.data().DocId? act.data().DocId:''
              }
              ]
              })
              })
          })
        })
      
      } //prende sottoman per manutentori

      const [evAtt,setEvAtt] = useState(false) //trigger popup evadi manutenzione
      const [scadEv,setScadEv] = useState(null) //variabile che salva la manutenzione da evadere

      function triggerEvadi(scad){
        setScadEv(scad)
        setEvAtt(true)

      }

      const [triggerElementi,setTriggerElementi] = useState(false) //trigger popup che mostra elementi
      const [attEle,setAttEle] = useState(null) //var che salva l att da visualizzare gli elementi


      function triggerElementiPop(scad){
        setAttEle(scad)
        setTriggerElementi(true)

      }

      function PopupElementi(props){

        const [filterEsito, setFilterEsito] = useState('default');
        const [findInput, setFindInput] = useState('');
        const history = useHistory();
        const [elementi,setElementi] = useState([]) 

        // Funzione per recuperare gli elementi
  function getElementi() {
    if (attEle.Elementi) {
      setElementi([]); // Reset per aggiornamento
      attEle.Elementi.forEach((elemento) => {
        const path = elemento.Elemento || elemento;
        db.doc(path).get().then((doczz) => {
          db.collection('Elementi').doc(doczz.data().ref).get().then((docz) => {
            setElementi((prev) => [
              ...prev,
              {
                Id: docz.id,
                ...docz.data(),
                ...elemento,
              },
            ]);
          });
        });
      });
    }
  }

  // Effettua il trigger della funzione `getElementi` quando il popup è attivato
  useEffect(() => {
    if (props.trigger) {
      getElementi();
    }
  }, [props.trigger]);


        function returnEle(ele) {
          return (
            (findInput === '' || ele.Nome.toLowerCase().includes(findInput) || ele.Categoria.toLowerCase().includes(findInput) || ele.Macrocategoria.toLowerCase().includes(findInput)) &&
            (filterEsito === 'default' || filterEsito === '' || (filterEsito === 'Positivo' && ele.Esito === 'Positivo') || (filterEsito === 'Negativo' && ele.Esito === 'Negativo'))
          );
        }

        return(
          <div className="pop-opac">  
          <div className="popup" style={{backgroundColor:"white", minHeight:'80vh', height:'fit-content', width:'72vw', left:'18vw'}} >
          <div style={{textAlign:'left', width: 'fit-content'}}>
              <button
                className="cancelIconButton"
                
                onClick={() => props.setTrigger(false)}
              >
                <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
              </button>
              </div>
              <h2  className="page-title" style={{marginLeft:'2rem',marginTop:'0.5rem'}}>ELEMENTI</h2>
              {/* Filtro e Ricerca */}
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '1vh',marginLeft:'2rem' }}>
                        <h4 className="normal-text">Filtri:</h4>
                        <select
                          onChange={(e) => setFilterEsito(e.target.value)}
                          className="input-style"
                          style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '1vw', width: 'fit-content', maxWidth: '12rem' }}
                        >
                          <option value="default" disabled>Esito elementi...</option>
                          <option value="">Tutti</option>
                          <option value="Positivo">Positivi</option>
                          <option value="Negativo">Negativi</option>
                        </select>
                        <input
                          onChange={(e) => setFindInput(e.target.value.toLowerCase())}
                          className="input-style2"
                          style={{ width: '30rem', height: '1rem', fontWeight: 'normal', fontFamily: 'Poppins', marginLeft: '1vw' }}
                          type="text"
                          placeholder="Cerca elemento per nome, categoria o sottocategoria..."
                        />
                      </div>
               {/* Lista degli elementi filtrati */}
        <div style={{ marginTop: '1rem', padding: '0 2rem',overflowY:'auto',height:'71vh',paddingBottom:'1rem' }}>
          {elementi && elementi.length > 0 ? (
           elementi.map((all, n) => (
              returnEle(all) ? (
                <div key={n} style={{ marginTop: '1rem', borderBottom: '1px solid lightgrey' }}>
                  <h4
                    className="normal-text2"
                    onClick={() => history.push(`/elementi/elemento/${all.Id}`)}
                    style={{
                      maxWidth: '30rem',
                      whiteSpace: 'nowrap',
                      color: '#192bc2',
                      fontWeight: 'bold',
                      fontSize: '1.1rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer'
                    }}
                  >
                    {all.Nome}
                  </h4>
                  <h4 className="sub-text">Categoria: <b>{all.Categoria}</b></h4>
                  <h4 className="sub-text">Sottocategoria: <b>{all.Macrocategoria}</b></h4>
                  <h4 className="sub-text">
                    Esito: <b style={{ color: all.Esito === 'Positivo' ? 'green' : 'red' }}>{all.Esito || 'In attesa di verifica...'}</b>
                  </h4>
                </div>
              ) : null
            ))
          ) : (
            <h4 className="medium-text" style={{ marginTop: '1rem' }}>Nessun elemento presente</h4>
          )}
        </div>
          </div>
          </div>
        )
      }

      const [triggerAllegati,setTriggerAllegati] = useState(false) //trigger popup che mostra allegati
      const [attAll,setAttAll] = useState(null) //var che salva l att da visualizzare gli allegati


      function triggerAllegatiPop(scad){
        setAttAll(scad)
        setTriggerAllegati(true)

      }

      function PopupAllegati(props){

        const storage = firebase.storage();
        const storageRef = storage.ref();

        const [allegati, setAllegati] = useState(attAll?.Allegati || []);
        const [uploadPercentage, setUploadPercentage] = useState(0);

         // Funzione per gestire i file selezionati
  function handleFile(e) {
    const files = Array.from(e.target.files);
    aggiungiAllegati(files);
  }

  // Aggiungi allegati al database e aggiorna la lista
  function aggiungiAllegati(files) {
    const newAllegati = allegati ? [...allegati] : [];
    files.forEach((file, index) => {
      const filePath = `${props.user.Email}/${attAll.key}/${file.name}`;
      const uploadTask = storageRef.child(filePath).put(file);

      uploadTask.on('state_changed', 
        snapshot => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setUploadPercentage(progress);
        },
        error => console.error(error),
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          newAllegati.push({
            Allegato: downloadURL,
            AggiuntoDa: props.user.Email,
            Nome: file.name,
            StorageRef: filePath
          });

          if (index === files.length - 1) { // Update al termine del caricamento
            db.collection("Manutenzioni").doc(attAll.key).update({ Allegati: newAllegati })
              .then(() => {
                setAllegati(newAllegati);
                setUploadPercentage(0);
                alert("Allegati aggiunti correttamente");
              })
              .catch(console.error);
          }
        }
      );
    });
  }
  // Funzione per eliminare un allegato
  function deleteAllegato(all) {
    if (window.confirm(`Sei sicuro di voler eliminare l'allegato: ${all.Nome}?`)) {
      const updatedAllegati = allegati.filter(allegato => allegato.Allegato !== all.Allegato);
      db.collection('Manutenzioni').doc(attAll.key).update({ Allegati: updatedAllegati })
        .then(() => {
          if (all.StorageRef) {
            const deleteRef = storageRef.child(all.StorageRef);
            deleteRef.delete().catch(console.error);
          }
          setAllegati(updatedAllegati);
        })
        .catch(console.error);
    }
  }


        return(
          <div className="pop-opac">  
          <div className="popup" style={{backgroundColor:"white", minHeight:'80vh', height:'fit-content', width:'72vw', left:'18vw'}} >
          <div style={{textAlign:'left', width: 'fit-content'}}>
              <button
                className="cancelIconButton"
                
                onClick={() => props.setTrigger(false)}
              >
                <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
              </button>
              </div>
              <h2  className="page-title" style={{marginLeft:'2rem',marginTop:'0.5rem'}}>ALLEGATI</h2>
          
               {/* Lista degli allegati */}
               <div style={{ marginTop: '1rem', padding: '0 2rem' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
            <FilePresentIcon style={{ color: 'black' }} />
            <h4 className="header-normal" style={{ marginLeft: '5px' }}>Allegati {uploadPercentage !== 0 ? `${uploadPercentage}%` : null}</h4>
            <Button component="label" style={{ marginLeft: "5px", backgroundColor: 'transparent', color: 'black', width: 'fit-content', cursor: 'pointer' }}>
              <AddIcon />
              <input hidden multiple type="file" onChange={handleFile} />
            </Button>
          </div>

          {allegati.length > 0 ? allegati.map((all, n) => (
            <div key={n} style={{ marginTop: '1rem', borderBottom: '1px solid lightgrey' }}>
              <h4 className="normal-text2" onClick={() => window.open(all.Allegato, "_blank")}
                style={{
                  maxWidth: '30rem', whiteSpace: 'nowrap', color: '#192bc2',
                  fontWeight: 'bold', fontSize: '0.9rem', overflow: 'hidden',
                  textOverflow: 'ellipsis', cursor: 'pointer'
                }}>{all.Nome}</h4>
              <IconButton className="transparent-button" style={{ width: 'fit-content' }} onClick={() => deleteAllegato(all)}>
                <DeleteIcon style={{ color: '#FF0060' }} />
              </IconButton>
            </div>
          )) : <h4 className="medium-text" style={{ marginTop: '1rem' }}>Nessun allegato presente</h4>}
        </div>
          </div>
          </div>
        )
      }

      const [editNoteId, setEditNoteId] = useState(null); // salva l'id della nota in modifica
      const [noteText, setNoteText] = useState(''); // testo della nota corrente in modifica

       // funzione per attivare la modifica delle note
  const toggleEditNote = (id, currentNote) => {
    setEditNoteId(id);
    setNoteText(currentNote);
  };

  // funzione per aggiornare la nota nel database
  const updateNote = (id) => {
    if(noteText===''||noteText===undefined){
      setEditNoteId(null);

      return
    }else{
      db.collection("Manutenzioni").doc(id).update({ Note: noteText })
      .then(() => {
        setEditNoteId(null);
        // aggiorna il contenuto delle note in locale dopo l'aggiornamento
        setActivity(prev => prev.map(act => act.key === id ? { ...act, Note: noteText } : act));
      })
      .catch(console.error);
    }
   
  };

  const [editDateId, setEditDateId] = useState(null); // salva l'id della nota in modifica
  const [dateText, setDateText] = useState(''); // testo della nota corrente in modifica

   // funzione per attivare la modifica delle note
const toggleEditDate = (id, currentNote) => {
setEditDateId(id);
setDateText(currentNote);

};

// funzione per aggiornare la nota nel database
const updateDate = (id) => {
  console.log(dateText)
if(dateText===''||dateText===undefined){
  setEditDateId(null);

  return
}else{
  db.collection("Manutenzioni").doc(id).update({ Data: dateText })
  .then(() => {
    setEditDateId(null);
    // aggiorna il contenuto delle note in locale dopo l'aggiornamento
    setActivity(prev => prev.map(act => act.key === id ? { ...act, Data: dateText } : act));
  })
  .catch(console.error);
}

};

  const DocumentoLink = (link) => {
    const stringa = link.link;
    console.log(link.link)
    const url = stringa.split("Documento: ")[1];

    return (
        <h4 style={{color: 'black',margin:0,marginTop:'15px'}}>
            Oggetto: <b  onClick={() => window.open(url, '_blank')} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}> Visualizza Documento</b>
        </h4>
    );
};

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      {triggerElementi===true&&<PopupElementi setTrigger={setTriggerElementi} trigger={triggerElementi}/>}
      {triggerAllegati===true&&<PopupAllegati setTrigger={setTriggerAllegati} trigger={triggerAllegati} user={props.user}/>}
      {evAtt===true&&<EvadiManutenzione
       info={props.user}
       data={scadEv}
       sede={scadEv.Sede}
       cliente={scadEv.Cliente}
       trigger={evAtt}
       setTrigger={setEvAtt}
       getAtt={getActsOff}
       userSede={scadEv.Sede}
       sede_cli={scadEv.Sede_cli}
       email={props.user.Email}
       sottoRef={scadEv.Id}
       main={props.scadenza}
       setScad={setActivity}
                        />}
        <div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <WarningIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Azioni correttive</h4>
      </div>
      {activity.length>0?activity.map((act,i)=>(
            <div key={i} style={{marginTop:'10px',borderBottom:'1px solid lightgrey',paddingBottom:'5vh'}}>
              <div>
                <h4 className="bold-cool" style={{color:'#192bc2',fontSize:'1.5rem',marginTop:0,marginBottom:0}}>{act.key}{act.Titolo}</h4>
                <h4 className="normal-cool" style={{marginTop:0,marginBottom:0}}>{act.Descrizione}</h4>

                {act.Elementi?<h4 className="normal-cool" style={{marginTop:'15px',marginBottom:0}}>Oggetto: <b style={{}}>{act.Oggetto}</b></h4>:
                act.Oggetto?<DocumentoLink link={act.Oggetto}/>:null}

<div style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                <h4 className="normal-cool" style={{margin:0}}>Data di intervento: </h4>
                {editDateId === act.key ? (
                  <input
                    value={dateText}
                    onChange={(e) => setDateText(e.target.value)}
                    type="date"
                    style={{marginLeft:'5px'}}
                  className="input-style"
                  />
                ) : (
                  <h4 className="normal-cool" style={{margin:0}}><b style={{ marginLeft: '5px' }}>{act.Data ? formatData(act.Data) : 'Non inserita '}</b></h4>
                )}
               {props.user.Tipo==='Cliente'?null:checkWriteS(props.user.Permissions)===true? <IconButton style={{width:'fit-content',marginLeft:'1vw'}} onClick={() => editDateId!==null?updateDate(act.key):toggleEditDate(act.key, act.Data)}>
                  <EditIcon style={{ color: '#192bc2' }} />
                </IconButton>:null}
              </div>
                <h4 className="normal-cool" style={{marginTop:'5px',marginBottom:0}}>Stato: <b style={{}}>{act.Stato}</b></h4>
                <h4 className="normal-cool" style={{marginTop:'5px',marginBottom:0}}>Esito: <b style={{}}>{act.Esito?act.Esito:"Disponibile una volta evaso l'intervento"}</b></h4>

                <h4 className="normal-cool" style={{marginTop:'5px',marginBottom:0}}>Osservazioni: <b style={{}}>{act.Report?act.Report:"Disponibili una volta evaso l'intervento"}</b></h4>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: 0 }}>
                <h4 className="normal-cool">Note:</h4>
                {editNoteId === act.key ? (
                  <textarea
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    onBlur={() => updateNote(act.key)}
                    style={{ marginLeft: '1vw', padding: '5px', fontFamily: 'Poppins', width: '100%' }}
                  />
                ) : (
                  <b style={{ marginLeft: '1vw' }}>{act.Note ? act.Note : '-'}</b>
                )}
               {props.user.Tipo==='Cliente'?null:checkWriteS(props.user.Permissions)===true? <IconButton style={{width:'fit-content',marginLeft:'1vw'}} onClick={() => editNoteId!==null?updateNote(act.key):toggleEditNote(act.key, act.Note)}>
                  <EditIcon style={{ color: '#192bc2' }} />
                </IconButton>:null}
              </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'15px'}}>
                {props.user.Tipo==='Cliente'?null:act.Stato!=='Conclusa'?checkWriteS(props.user.Permissions)===true?<h2 style={{fontSize:'0.8vw', color:'#fb8500', cursor: 'pointer', fontWeight:'bold',borderBottom:'0.5px solid #fb8500',fontFamily:'Poppins',width:'fit-content',marginTop:0,marginBottom:0,padding:5}} onClick={()=>triggerEvadi(act)}>Evadi</h2>:null:null}
                {act.Elementi?<Tooltip title='Elementi'>
                <IconButton onClick={()=>triggerElementiPop(act)} style={{marginLeft:props.user.Tipo==='Cliente'?0:act.Stato!=='Conclusa'?'1vw':0,width:'fit-content',backgroundColor:'#192bc2'}}>
                <FireExtinguisherIcon style={{color:'white'}}/>
                </IconButton>
                </Tooltip>:null}
                <Tooltip title='Allegati'>
                <IconButton onClick={()=>triggerAllegatiPop(act)} style={{marginLeft:'1vw',width:'fit-content',backgroundColor:'#192bc2'}}>
                <FilePresentIcon style={{color:'white'}}/>
                </IconButton>
                </Tooltip>

                </div>
                </div>      
            </div>
        )):<h4>Nessuna azione correttiva presente</h4>}
      </div>
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Recupero;