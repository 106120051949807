import React,{useState,useEffect,useCallback,useRef} from 'react'
import { GoogleMap, useLoadScript, Marker, OverlayView } from "@react-google-maps/api";
import { getIcon } from '../../../EngineClienti/engineClienti';
import locationIcon from '../../../images/location.png';

const Mappa = (props) => {

    

      const mapInstanceRef = useRef();
      const [zoom, setZoom] = useState(20); // stato per gestire lo zoom
      const [showMarkers,setShowMarkers] = useState(true) //se mostrare i markers durante lo zoom o no
      const refContainer = useRef()




      const onLoad = useCallback((map) => {
        mapInstanceRef.current = map;
      }, []);
    
      const handleZoomChanged = useCallback(() => {
        if (mapInstanceRef.current) {
          const newZoom = mapInstanceRef.current.getZoom();
          setZoom(newZoom);
            setShowMarkers(newZoom>18)
          
        }
      }, []);
      function renderMarkerSott(m){
        var arr = [...props.sottocategorie]
        console.log(arr,m)
        var index = arr.findIndex((e)=>e.Nome===m.Macrocategoria)
        if(index!==-1){
          if(arr[index].Visualize===true){
            return true
          }
        }
       
      }
      function deleteMarker(e,i){
        var newArr = [...props.elementiPosizionati]
        var arr2 = [...props.elementi]
        
        props.db.collection('Elementi').doc(newArr[i].Id).update({
          Piano:'',
          Mappa:'',
          Posizione: null
        }).then(()=>{
          console.log(newArr[i])
          arr2.push(newArr[i])
          console.log(arr2)
          props.setElementi(arr2)
          newArr.splice(i,1)
          props.setElementiPosizionati(newArr)
         
          props.db.collection('Mappe').doc(props.mappa_id).update({
            Elementi: newArr,
            UltimaModifica:new Date().toLocaleDateString("en-GB"),
          })
        })
  
          } //elimina marker
    
          function handleSpostamentoMarker(e,i){
            let newArr = [...props.elementiPosizionati];
            newArr[i].Posizione = {Lat: e.latLng.lat(), Lng: e.latLng.lng()};
             props.setElementiPosizionati(newArr)
          
              props.db.collection("Elementi").doc(newArr[i].Id).update({
                  Posizione: {Lat: e.latLng.lat(), Lng: e.latLng.lng()},
                })
                props.db.collection('Mappe').doc(props.mappa_id).update({
                  UltimaModifica:new Date().toLocaleDateString("en-GB"),
      
                })
    
        }
    
        const  handleClick= useCallback((e) => {
            if (props.mode === 1) {
              if(props.elementi.length>0){
    
              
              var currentSott = props.selElement.Macrocategoria //sottocat corrente
    
              //aggiunge elemento posizionato
              const newPosition = {
                ...props.selElement,
                Posizione: { Lat: e.latLng.lat(), Lng: e.latLng.lng() }
              };
              var arr = [...props.elementiPosizionati]
              arr.push(newPosition)
              //setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() })
              props.setElementiPosizionati(prev => [...prev, newPosition]);
              props.db.collection('Elementi').doc(props.selElement.Id).update({
                Posizione:{ Lat: e.latLng.lat(), Lng: e.latLng.lng() },
                Piano: props.piano_id,
                Mappa: props.mappa_id,
    
              })
              props.db.collection('Mappe').doc(props.mappa_id).update({
                Elementi: arr,
                UltimaModifica:new Date().toLocaleDateString("en-GB"),
    
              })
    
              //rimuovi elemento posizionato da elementi disponibili
              const updatedElementi = props.elementi.filter(el => !(el.Nome === props.selElement.Nome && el.Macrocategoria === props.selElement.Macrocategoria));
              props.setElementi(updatedElementi);
              
              //seleziona nuovo elemento
              if (updatedElementi.length > 0) {
                const nextElement = updatedElementi.find(el => el.Macrocategoria === currentSott) || updatedElementi[0];
                props.setSelElement(nextElement);
              }
             
            }
            }
            
    
          },[props.mode,props.elementi,props.selElement]) //gestisce i click sulla mappa


  return (
    !props.isLoaded||props.mappa===null ? (
        <h4>caricamento</h4>
      ) : (
        <div
          style={{
            position: "relative",
            zIndex: 5,
            maxWidth: "100%",
            marginLeft: "-2.5rem",
          }}
        >
          <GoogleMap
            onClick={props.editMode===0?handleClick:null}
            zoom={zoom}
            center={props.center}
            onZoomChanged={handleZoomChanged}
            ref={props.mapRef}
            onLoad={onLoad}
            mapContainerStyle={{ width: "100%",height:'85vh',marginLeft:'2vw'}}
            mapContainerClassName="map-container"
            options={{ minZoom: 10, maxZoom: 25 }}
          >
            {props.boundss && (
              <OverlayView
                mapPaneName="overlayLayer"
                ref={refContainer}
                id="supercontainer"
                position={props.center}
                bounds={props.boundss}
                style={{
                  border: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      left: "50%",
                      top: "50%",
                    }}
                  >
                      <img
                        src={props.mappa.Planimetria}
                        alt='plan'
                        style={{
                          width: "100%",
                          opacity: props.opacity+'%',
                          transformOrigin:
                            props.mappa.TranslateSx + "% " + props.mappa.TranslateTop + "%",
                          transform:
                            "translate(-" +
                            props.mappa.TranslateSx +
                            "%, -" +
                            props.mappa.TranslateTop +
                            "%) rotate(" +
                            props.mappa.Rotation +
                            "deg) scale(" +
                            props.mappa.Zoom +
                            ")",
                        }}
                      />
                
                  </div>
                </div>
              </OverlayView>
            )}
            {showMarkers === true ?
          props.elementiPosizionati.map((marker,m) => (
                    renderMarkerSott(marker)===true?<Marker key={m} 
                    onClick={(e)=>props.mode===0?props.history.push(`/elementi/elemento/${marker.Id}`):props.editMode===1?deleteMarker(e,m):props.history.push(`/elementi/elemento/${marker.Id}`)} 
                    draggable={props.mode===1&&props.editMode===0} onDragEnd={(e)=>props.editMode===0?handleSpostamentoMarker(e,m):null}
                     position={new window.google.maps.LatLng(marker.Posizione.Lat,marker.Posizione.Lng)} label={{text:marker.Nome, 
                      color:marker.EsitoUltimaScadenza?marker.EsitoUltimaScadenza==='Positivo'?'black':'red':'black',
                      fontWeight:'bold'}} title={marker.Nome} 
                    icon={{url:getIcon(marker),scaledSize: new window.google.maps.Size(20,20),labelOrigin: new window.google.maps.Point(13,30)}}/>:null
                  )):<Marker draggable={false}   icon={{url:locationIcon,scaledSize: new window.google.maps.Size(100,100)
                  }} bounds={props.boundss} position={props.center}/>}
            
          </GoogleMap>
        </div>
      )
  )
}

export default Mappa