import React, { useEffect, useState,useRef,useCallback } from 'react'
import { useParams } from 'react-router-dom'
import NavBar2 from '../../NavBar/NavBar2'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import firebase from "firebase";
import { getElementiCliente, getMappa } from '../../EngineOffritore/engineOffritore';
import { getElementiMappa, getUtente,checkDeleteS,checkWriteS } from '../../EngineClienti/engineClienti';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GoogleMap, useLoadScript, Marker, OverlayView } from "@react-google-maps/api";
import locationIcon from '../../images/location.png';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MapIcon from '@mui/icons-material/Map';

import Menu from './EditMappa/Menu';
import { getIcon } from '../../EngineClienti/engineClienti';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import NavBar from '../../NavBar/NavBar';

import Mappa from './EditMappa/Mappa';
const EditMappa = ({history}) => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBy4500tb8vImwBqAyfom60eOXjoW1fQfI&libraries=places",
    
      }); //chiave api per la mappa

    const {user_id, sede_id,piano_id,mappa_id} = useParams()
    const mapRef = useRef()
    const mapInstanceRef = useRef();
    const refContainer = useRef()
    const db = firebase.firestore()
    const auth = firebase.auth()

    const [mappa,setMappa] = useState(null)
    const [center,setCenter] = useState(null)
    const [boundss,setBoundss] = useState(null)
    const [opacity,setOpacity] = useState(100)
    const [mode,setMode] = useState(0) //modalità mappa
    const [user,setUser] = useState(null)
    const [elementi,setElementi] = useState([]) //lista elementi non posizionati
    const [elementiPosizionati,setElementiPosizionati] = useState([]) //lista elementi posizionati
    const [sottocategorie,setSottocategorie] = useState([]) //lista sottocategorie
    const [categorie,setCategorie] = useState([]) //lista categorie
 
    const [selElement,setSelElement] = useState(null) //elemento selezionato per posizionamento
    const [zoom, setZoom] = useState(20); // stato per gestire lo zoom
    const [showMarkers,setShowMarkers] = useState(true) //se mostrare i markers durante lo zoom o no
    const [editMode,setEditMode] = useState(0) //modalità modifica -> 0 inserimento e drag elementi posizionati, 1 eliminazione (click su elemento)

    //cambio nome mappa
    const [editName,setEditName] = useState(false)
    const [newName,setNewName] = useState('')

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
            if (user) {
                getUtente(db,auth,setUser)
                getMappa(db,mappa_id,setMappa)
            }
        })
    },[auth])

    useEffect(()=>{

            if (user!==null) {
              console.log(user.Email)
              if(user.Tipo==='Lavoratore'){
                getElementiCliente(db,user.Email,user_id,setElementi,setCategorie,setSottocategorie,setElementiPosizionati,mappa_id,sede_id)

                const currentUrl = window.location.href;
      
                if(currentUrl.includes('visualizemappa')){
                  setMode(0)
                }else{
                  setMode(1)
                }

              }else{
                getElementiMappa(db,user.Email,setElementi,setCategorie,setSottocategorie,setElementiPosizionati,mappa_id,sede_id)
              }
            }
    },[user])

    useEffect(()=>{
      if(elementi.length>0){
        setSelElement(elementi[0])
        
      }
    },[elementi])

      function editMapName(){
        if(newName!==''){
          db.collection('Mappe').doc(mappa_id).update({
            Nome: newName,
            UltimaModifica:new Date().toLocaleDateString("en-GB"),

          }).then(()=>{
            setEditName(false)
            var m = mappa
            m.Nome=newName
            setMappa(m)
            setNewName('')
            
          })
        }else{
          alert('Inserire un nome valido.')
        }
        
      }
      useEffect(()=>{
        if(mappa){
            setMappa(mappa)
        }
      },[mappa])

      useEffect(()=>{
     
        if(mappa!==null&&isLoaded){
          setMapRef()
        }
    },[mappa,isLoaded])

    function setMapRef(){
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(mappa.FirstBound.Lat, mappa.FirstBound.Lng),
        new window.google.maps.LatLng(mappa.SecondBound.Lat, mappa.SecondBound.Lng)
      );
        setCenter(
          bounds.getCenter()
          );
          setBoundss(
            bounds
          );
    }

    function changeMode(){
      if(mode===0){ //cambia in planimetria
          setMode(2) 
      } 
      if(mode===2){ //cambia in inserimento
          if(checkWriteS(user.Permissions)===true){
              setMode(1) 

          }else{
              setMode(0)
          }
      }
      if(mode===1){ //cambia in visualizzazione
          setMode(0) 
      }
     
    }

  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
    {user!==null?user.Tipo==='Lavoratore'?<NavBar2/>:<NavBar/>:null}
    <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
    <div  style={{  width: "84.9vw",display:'flex',paddingTop:'1.2rem',backgroundColor:'white',border:'1px solid lightgrey',height:'8vh' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          
            {editName===false?<div style={{display:'flex',alignItems:'center'}}>
            <h4 className="page-title" style={{margin:0,fontSize:'1.5vw'}}>{mappa!==null?mappa.Nome:null}</h4>
           {checkWriteS(user?user.Permissions:null)===true? <IconButton onClick={()=>setEditName(true)} style={{width:'fit-content',marginLeft:'1vw'}}><EditIcon style={{color:'#14213d'}}/></IconButton>:null}
            </div>:<div style={{display:'flex',alignItems:'center'}}>
            <input className='input-style' placeholder='Inserisci nome mappa...' type='text' onChange={(e)=>setNewName(e.target.value)}></input>
            <IconButton onClick={editMapName} style={{width:'fit-content',marginLeft:'1vw'}}><CheckIcon style={{color:'green'}}/></IconButton>
            <IconButton onClick={()=>{setEditName(false);setNewName('')}} style={{width:'fit-content',marginLeft:'0.5vw'}}><CloseIcon style={{color:'red'}}/></IconButton>

            </div>}
          <h4
          className="sub-text-b"
        >
          Modifica mappa
        </h4>
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'auto',marginRight:'2vw'}}>
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginRight:'1vw'}}>
          <div onClick={()=>user.Tipo==='Lavoratore'?setMode(0):null} style={{cursor:'pointer',width:'2.2vw',height:'2.2vw',
          borderRadius:50,backgroundColor:mode===0?'#192bc2':'white',border:'1px solid #14213d',
            display:'flex',alignItems:'center',justifyContent:'center'}}>
            <VisibilityIcon style={{fontSize:'1.2vw',color:mode===0?'white':'#14213d'}}/>
            </div>
            <h4 style={{cursor:'pointer',margin:0,fontSize:'12px', color:mode===0?'#192bc2':'black',
          fontFamily:'Poppins',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',}}>Visualizzazione</h4>
          </div>

          {user?.Tipo==='Lavoratore'?<div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginRight:'1vw'}}>
          <div onClick={()=>user.Tipo==='Lavoratore'?setMode(1):null} style={{cursor:'pointer',width:'2.2vw',height:'2.2vw',
          borderRadius:50,backgroundColor:mode===1?'#192bc2':'white',border:'1px solid #14213d',
            display:'flex',alignItems:'center',justifyContent:'center'}}>
            <EditIcon style={{fontSize:'1.2vw',color:mode===1?'white':'#14213d'}}/>
            </div>
            <h4 style={{cursor:'pointer',margin:0,fontSize:'12px', color:mode===1?'#192bc2':'black',
          fontFamily:'Poppins',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',}}>Modifica/Inserisci</h4>
          </div>:null}
           
          {user?.Tipo==='Lavoratore'?<div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
          <div onClick={()=>user.Tipo==='Lavoratore'?setMode(2):null} style={{cursor:'pointer',width:'2.2vw',height:'2.2vw',
          borderRadius:50,backgroundColor:mode===2?'#192bc2':'white',border:'1px solid #14213d',
            display:'flex',alignItems:'center',justifyContent:'center'}}>
            <MapIcon style={{fontSize:'1.2vw',color:mode===2?'white':'#14213d'}}/>
            </div>
            <h4 style={{cursor:'pointer',margin:0,fontSize:'12px', color:mode===2?'#192bc2':'black',
          fontFamily:'Poppins',marginLeft:'auto',marginTop:'auto',marginBottom:'auto',}}>Planimetria</h4>
          </div>:null}
           
          </div>
          
        </div>
        {/*DIV CHE CONTIENE MAPPA E MENÙ*/}
        <div style={{position:'relative',width:'100%',height:'85vh',display:'flex',borderLeft:'1px solid lightgrey',paddingLeft:'2px',borderBottom:'1px solid lightgrey'}}>
        {/*MAPPA */}
        <div style={{width:'75%',height:'100%',position:'relative'}}>
       {mode===1?selElement? <div style={{position:'absolute',zIndex:100,left:'50%',transform: 'translate(-50%)',backgroundColor:'white',padding:5,top:'10px',border:'1px solid lightgrey'}}>
        {editMode===0?<h4 style={{margin:0}} className='normal-cool'>Elemento da posizionare: <b style={{color:'#192bc2'}}>{selElement.Nome}</b></h4>:
        <h4 style={{margin:0}} className='normal-cool'>Clicca sull'elemento che desideri rimuovere dalla mappa.</h4>}
        </div>:null:null}
        {/*<Map opacity={opacity}/>*/}
       <Mappa center={center} boundss={boundss} mappa={mappa} isLoaded={isLoaded} mapRef={mapRef} editMode={editMode} piano_id={piano_id} setElementiPosizionati={setElementiPosizionati} setElementi={setElementi} elementi={elementi} db={db} auth={auth} history={history}
        opacity={opacity} elementiPosizionati={elementiPosizionati}
        sottocategorie={sottocategorie} mode={mode}  mappa_id={mappa_id} selElement={selElement} setSelElement={setSelElement}></Mappa>
        </div>
        {/*MENU */}
        <Menu opacity={opacity} mappa={mappa} setOpacity={setOpacity} user={user} mode={mode} setZoom={setZoom} setCenter={setCenter} elementiPosizionati={elementiPosizionati} setSelElement={setSelElement} editMode={editMode} setEditMode={setEditMode} selElement={selElement} setMode={setMode} categorie={categorie} sottocategorie={sottocategorie} setSottocategorie={setSottocategorie} elementi={elementi}/>
        </div>

        </div>
    </div>
  )
}

export default EditMappa