//pagina iniziale di quando si accede al sito
import React from "react";
import { withRouter } from "react-router";
import UpperBar from "./Components/UpperBar";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import logoMan from ".././images/home-mysic.png"
import logoCli from ".././images/home_cli.jpg"
import logo from '.././images/logo.png'
import opt from '.././images/optimized.png'
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import BuildIcon from '@mui/icons-material/Build';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GridLayout from "./Components/GridLayout";
import './Style/prelogin.css'
import logoExit from '.././images/exit.png'

import Footer from "./Components/Footer";
const PreLoginPage = ({ history }) => {
  const navigate = useHistory();

  function handleClick() {
    navigate.push("/register");
  } //manda alla pagina registrati
  function handleClick2() {
    navigate.push("/login");
  } //manda alla pagina registrati

  return (
 <div
  className='pre-all-cont'
  style={{backgroundColor: 'white',
    backgroundImage: `
      radial-gradient(circle at 20% 20%, rgba(255, 99, 71, 0.2) 10%, transparent 30%),
      radial-gradient(circle at 80% 40%, rgba(30, 144, 255, 0.2) 10%, transparent 30%),
      radial-gradient(circle at 60% 80%, rgba(128, 128, 128, 0.2) 10%, transparent 30%),
      radial-gradient(circle at 40% 70%, rgba(100, 149, 237, 0.2) 10%, transparent 30%)
    `,
    backgroundSize: 'cover', width:'100vw',overflowX:'hidden',height:'100vh'}}
 >
 <UpperBar/>
 <div style={{height:'80vh'}} className="pre-container2">
    <div className="pre-content2"></div>


    <div style={{backgroundColor:'white',width:'20vw',paddingLeft:'1vw',borderRadius:'20px',padding:'50px',boxShadow: "rgba(0, 0, 0, 0.05) 0px 1.95px 1.6px 2px",height:'55vh'
}} className="pre-content">

 <h4 className="pre-text-big" style={{fontFamily:'Poppins',color:'black',fontWeight:'500',marginBottom:0,marginTop:0}}>Sicurezza in</h4>
 <h4 className="pre-text-big" style={{fontFamily:'Poppins',color:'black',fontWeight:'500',marginTop:'-1.5vh',marginBottom:0}}>Cloud</h4>
 <h4 className="pre-text-big" style={{fontFamily:'Poppins',color:'black',fontWeight:'200',marginTop:'-1.5vh',marginBottom:0}}>365 giorni</h4>
 <p style={{fontFamily:'Poppins',color:'black',fontWeight:'200',marginBottom:0,}}>Rendi la tua azienda più sicura ed efficiente con Mysic: il gestionale che semplifica la sicurezza. Organizzati meglio, agisci prima! </p>

 <button
     style={{
       backgroundColor: "#192bc2",
       color: "white",
       fontWeight: "bold",
       fontFamily: "Montserrat",
       fontSize: "0.7vw",
       border: "2px solid #192bc2",
       borderRadius: 5,
       width: "100%",
       cursor: "pointer",
       height: "4vh",
       display: "flex",
       marginTop:"3vh",
       
     }}
     onClick={handleClick}
   >
     <h4 style={{marginTop:'auto',marginBottom:'auto',fontSize:'0.8vw',fontFamily:'Poppins',fontWeight:'500',marginLeft:'auto',marginRight:'auto'}}>Iscriviti</h4>
   </button>
    <div style={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'center',marginTop:'4vh',}}>
   <p style={{fontFamily:'Poppins',color:'black',fontWeight:'200',marginBottom:0,marginLeft:'auto',width:'fit-content',fontSize:'15px'}}>Hai già un account? </p>
   <p
     style={{
       fontFamily:'Poppins',color:'#14213d',marginBottom:0,width:'fit-content',fontSize:'15px',marginLeft:'5px',marginRight:'auto',borderBottom:'1px solid #14213d',cursor:'pointer'

       
     }}
     onClick={handleClick2}
   >
   Accedi
   </p>
   </div>

 </div>
 
 <div className='pre-content2'>
  <div style={{display:'flex'}}>
     <div>
     <div style={{boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',width:'21vw',height:'fit-content',border:'none'
 ,borderRadius:50,backgroundColor:'white',padding:5,display:'flex'}}>
   <img src={opt} style={{width:'1vw',marginLeft:'0.5vw'}} alt='optimized'></img>
         <h4 style={{marginTop:0,fontFamily:'Poppins',fontWeight:'400',color:'gray',marginBottom:0,height:'fit-content',
         fontSize:'0.75vw',marginLeft:'0.5vw',marginRight:'auto',width:'fit-content'}}>Account ottimizzati per manutentori e clienti</h4>

     </div>
     <img style={{width:'24vw',right:'25vw',borderRadius:20,marginTop:10}} alt='off' src={logoCli}></img>
     <div style={{marginTop:10,marginLeft:'auto',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',width:'fit-content',height:'fit-content',border:'none',borderRadius:20,backgroundColor:'white',padding:5,display:'flex',paddingRight:'10px'}}>
         <img src={logo} style={{width:'1.7vw',marginLeft:'0.5vw'}} alt='logo_mysic'></img>

       <h4 style={{marginLeft:'0.5vw',marginTop:'auto',fontFamily:'Poppins',fontWeight:'400',color:'gray',marginBottom:'auto',fontSize:'0.75vw'}}>Un account, la totale gestione della tua sicurezza.</h4>
 </div>
 <div style={{width:'fit-content',height:'fit-content',border:'none'
 ,borderRadius:20,padding:5,display:'flex',marginTop:10,marginLeft:'auto'}}>
   <Tooltip title='Scadenzario'>
 <IconButton  style={{width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#14213d'}}>
 <CalendarMonthIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Documenti'>
 <IconButton  style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#14213d'}}>
 <ArticleRoundedIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Elementi'>
 <IconButton  style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#14213d'}}>
 <SecurityRoundedIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Interventi'>
 <IconButton  style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#14213d'}}>
 <AssignmentLateRoundedIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Manutenzioni'>
 <IconButton  style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#14213d'}}>
 <BuildIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>
 <Tooltip title='Mappe'>
 <IconButton style={{marginLeft:'1vw',width:'2vw',height:'2vw',borderRadius:50,backgroundColor:'#14213d'}}>
 <MapRoundedIcon style={{color:'white'}}/>
 </IconButton>
 </Tooltip>

 </div>
     </div>
     <div style={{marginLeft:10}}>
     <img style={{height:'50vh',borderRadius:20}} alt='off' src={logoMan}></img>

     </div>
  </div>
 </div>
 <div className="pre-content2"></div>
 </div>
 <div style={{position:'relative',width:'100vw',height:'110vh'}}>
 <GridLayout/>
 </div>
 <div style={{width:'100%',height:'50vh',flexDirection:'column',alignItems:'center',justifyContent:'center',display:'flex',
   backgroundImage: `url(${logoExit})`,
   backgroundSize: 'cover', // Adatta l'immagine al contenitore
   backgroundPosition: 'center', // Centra l'immagine
   backgroundRepeat: 'no-repeat', // Evita la ripetizione dell'immagine
   backgroundColor:'black',
   position:'relative'
 }}>
   <div
    style={{
      position: "absolute", // Overlay posizionato sopra
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.8)", // Colore nero con opacità al 50%
      zIndex: 1, // Assicura che l'overlay sia sopra lo sfondo
    }}
  />
  <div style={{ position: "relative", zIndex: 2,flexDirection:'column',alignItems:'center',justifyContent:'center',display:'flex', }}>
            <h4 style={{margin:0,fontSize:'25px',color:'white'}}>Che aspetti? Cambia il tuo modo di vivere la sicurezza</h4>
            <button
     style={{
       backgroundColor: "white",
       color: "white",
       fontWeight: "bold",
       fontFamily: "Montserrat",
       fontSize: "0.7vw",
       border: "none",
       borderRadius: 5,
       width: "30%",
       cursor: "pointer",
       height: "4vh",
       display: "flex",
       marginTop:"3vh",
       
     }}
     onClick={handleClick}
   >
     <h4 style={{marginTop:'auto',marginBottom:'auto',fontSize:'0.8vw',fontFamily:'Poppins',fontWeight:'500',marginLeft:'auto',marginRight:'auto',color:'#192bc2'}}>ISCRIVITI ORA</h4>
   </button>
   </div>
              </div>
 <div style={{position:'relative',height:'10vh'}}>
 <Footer></Footer>

 </div>
 </div>

   
  );
};

export default withRouter(PreLoginPage);
