//chiave firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBSYPE8mE-asVjbZKoIKZ0zUKEORKfy6gU",
  authDomain: "mysic-817f9.firebaseapp.com",
  projectId: "mysic-817f9",
  storageBucket: "mysic-817f9.appspot.com",
  messagingSenderId: "656109324681",
  appId: "1:656109324681:web:c56b3534c67be722bff958",
  measurementId: "G-G3TXDH2M33",
});
const storageRef = firebase.storage().ref();
export default app;




