//sezione altro
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import firebase from "firebase";
import * as XLSX from "xlsx/xlsx.mjs";
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import { IconButton, Tooltip } from "@mui/material";
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import Lottie from "lottie-react";
import loading from '../../images/loading.json'
import Loading from "../../loading";
const AltroS = ({history}) => {

    const db = firebase.firestore(); //database firebase
    const auth = firebase.auth(); //autentication firebase


    const [ele,setEle] = useState() //numero elementi acc
    const [saveSede,setSaveSede] = useState(null) //var opzione salva sede
    const [elements,setElements] = useState([]) //lista elementi
    const [plan,setPlan] = useState(null) //tipo di piano mysic account
    const [codes,setCodes] = useState([]) //array per i codici
    const [email,setEmail] = useState('') //email
    const [azienda,setAzienda] = useState('') //nome azienda 
    const [tipo,setTipo] = useState(null) //tipo account
    const [interventi,setInterventi] = useState([]) //lista interventi
    const [documenti, setDocumenti] = useState([]) //lista doc
    const [manutenzioni,setManutenzioni] = useState([]) //lista manutenzioni

    const [saveEmail,setSaveEmail] = useState(null) //var opzione salva sede

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
            if (user) {
        db.collection("UsersId")
        .doc(auth.currentUser.uid)
        .get()
        .then((doc) => {
          db.collection('Users').doc(doc.data().Email).get().then((docc)=>{
            var emailz = ''

            if (docc.data().Tipo === "SottoAccount") {
              db.collection('UsersId').doc(docc.data().MasterAccount).get().then((doccc)=>{
                setEmail(doccc.data().Email)
                getCodes(doccc.data().Email)

                db.collection("Users")
          .doc(doccc.data().Email)
          .get()
          .then((docz) => {
              setTipo(docz.data().Tipo)
              setEle(docz.data().Current_Ele);
              setPlan(docz.data().Plan)
              setAzienda(docz.data().Nome_Azienda)
  

          })

              })
          }else{
            setEmail(doc.data().Email)
            getCodes(doc.data().Email)
            if(docc.data().Notificaemail){
                setSaveEmail(docc.data().Notificaemail)

            }else{
                setSaveEmail('false')
            }
          
                  setTipo(docc.data().Tipo)
                  setEle(docc.data().Current_Ele);
                  setPlan(docc.data().Plan)
                  setAzienda(docc.data().Nome_Azienda)
          }

          
          })

                  
        });
  
    }
})

   /*if(doc.data().SalvaSede!==null&&doc.data().SalvaSede!==undefined){
            if(doc.data().SalvaSede===false){
                setSaveSede(false)
                console.log(doc.data().SalvaSede)
            }else{
                setSaveSede(true)
                console.log(doc.data().SalvaSede)
            }
          }    */  
        
    },[auth]) //prende dati e chiama funzione getCodes

    
    function getCodes(email){
        setCodes([])
    db.collection('Users').doc(email).collection('Codes').get().then((codes)=>{
    if(codes.docs.length>0){
    codes.forEach((cx)=>{
        db.collection('Codes').doc(cx.id).get().then((cxz)=>{
            setCodes((prev)=>{
                return [
                    ...prev,
                    {
                        Id: cx.id,
            Operator: cxz.data().Operator,
            Azienda: cxz.data().Azienda,
            Riscattato:cxz.data().Riscattato,
            Tipo: cxz.data().Tipo
                    }
                ]
            })
        })
    })
    }
    
    })

    } //funzione che prende i codici
    function writeSaveSede(){
        console.log(saveSede)
        db.collection('Users').doc(auth.currentUser.email).update({
            SalvaSede: saveSede===true||saveSede===null?false:true
        }).then(()=>{
            var s = saveSede===true||saveSede===null?false:true
            setSaveSede(s)
        })   
    } //aggiorna salvataggio sede o meno

    function writeSaveEmail(){
        console.log(saveEmail)
        db.collection('Users').doc(email).update({
            Notificaemail: saveEmail==='true'?'false':'true'
        }).then(()=>{
            var s = saveEmail==='true'?'false':'true'
            setSaveEmail(s)
        })   
    } //aggiorna salvataggio sede o meno


      function generateCodes(){
        if(plan===0){//normale
             makeid(15,0)

            
        }else if(plan===1){ //pro
            for(let i=0;i<3;i++){
                var type = 0

                if(i===2){
                    type=1
                }else{
                    type=0
                }	
                makeid(15,type)
                switch(i){
                    case 0:
                    
                    break;
                    case 1:
                       
                    break;
                    case 2:
                     alert('Codici generati correttamente!')
                    break;
                    default:
                }
            }

        }else{ //pro xl
 
            for(let i=0;i<5;i++){
                var type = 0

                if(i>2){
                    type=2
                }else if(i>0&&i<3){
                    type=1
                }else{
                    type=0
                }	
                 makeid(15,type)

                switch(i){
                    case 0:
                   
                    break;
                    case 1:
                       
                    break;
                    case 2:
                       
                    break;
                    case 3:
                    
                    break;
                    case 4:
                       alert('Codici generati correttamente!')
                    break;
                    
                    default:
                    console.log('bella')    
                }  
            }
        }
      } //funzione che genera i codici

      function makeid(length,type) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        db.collection('Codes').doc(result).get().then((cx)=>{
            if(cx.exists){

            }else{
                db.collection('Codes').doc(result).set({
                    Id: result,
                    Operator: email,
                    Azienda: azienda,
                    Riscattato: false,
                    Tipo: type
                }).then(()=>{
                    db.collection('Users').doc(email).collection('Codes').doc(result).set({
                        id: result
                    }).then(()=>{
                        setCodes((prev)=>{
                            return [
                                ...prev,
                                {
                                    Id: result,
                        Operator: email,
                        Azienda: azienda,
                        Riscattato: false,
                        Tipo: type
                                }
                            ]
                        })
                    })
                    
                })

            }
        })
        return(result)
    } //funzione che crea il codice

    return <div style={{paddingLeft:'1rem',height:'88vh',overflowY:'auto',width:'71vw'}}>
                      <h4 className="title-section" style={{marginTop:'0.2rem'}}>Altro</h4>
    {tipo?<div>
    {tipo==='Cliente'?<div style={{display:'flex',marginTop:'2vh',}}>
        {console.log(saveEmail)}
    <input type='checkbox' style={{marginBottom:'auto',marginTop:'8px'}}  onChange={()=>writeSaveEmail()} checked={saveEmail===null?false:saveEmail==='true'?true:false}></input>
    <div style={{marginLeft:'5px'}}>
    <h4 className="normal-text" >Ricevi notifiche su criticità </h4>
    <h4 className="sub-text" style={{}}>Attivando questa opzione, una volta al mese ti verrà inviata una email con il numero di cricità presenti nel tuo account.</h4>

        </div>
    </div>:null}
   
    
    {tipo==='Cliente'?null:<div style={{}}>
    <h4 className="header-normal">I tuoi codici: </h4>
    <ul>
    {codes.map((map)=>(
                <li><div>
                    <div style={{display:'flex',alignItems:'center'}}>
                     <h4 className="normal-text" style={{marginRight:'5px'}}>{map.Id}</h4>   
                     <Tooltip title={map.Riscattato===false?'Disponibile':'Già utilizzato'}>{map.Riscattato===false?<CheckCircleSharpIcon style={{color:'green'}}/>:<CancelSharpIcon style={{color:'red'}}/>}</Tooltip>
                     {map.Riscattato===false?<IconButton style={{width:'fit-content'}} onClick={()=>{navigator.clipboard.writeText(map.Id)}}>
                    <ContentCopySharpIcon style={{fontSize:'1.2rem'}}/>
                    </IconButton>:null}
                    </div>
                    <div style={{display:'flex'}}>
                    <h4 className="sub-text">Tipo account:</h4>
                <h4 className="sub-text-b" style={{marginLeft:'5px'}}> {map.Tipo===0?'Normale':map.Tipo===1?'PRO':'PRO XL'}</h4>
                    </div>
               

                </div></li>

    ))}

    </ul>
    {codes.length>0?null:<button onClick={()=>generateCodes()} className="blue-button" style={{marginLeft:-5,marginTop:10}}>Genera</button>}

        </div>}
        </div>:<Loading/>}
    </div>
}

export default AltroS;